import {reactive, toRefs, computed, defineComponent, onBeforeMount, onMounted, getCurrentInstance, provide, nextTick} from 'vue';
import SplitOrderListUtil ,{ISplitOrderListDataObj} from './splitOrderListUtil';
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";

export default defineComponent ({
    name: 'SplitOrderList',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:ISplitOrderListDataObj=reactive<ISplitOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'维护评审表',
                isShowFixCol:false,
                multiple: true,//是否允许多选
                expand: true,
                border: false,
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',
                    cardFrom:'SplitOrderList',
                },
                modelMethod: utils.OrderProviderApi.buildUrl('/splitOrder/pageData')
            },
            otherParams:{
                belongMaxId:'',
                visible:false,//订单评审弹出框隐藏/显示控制标识
                approveLog:{//审批日志
                    visible:false,
                    data:[]
                },
                fpdzzy:{
                    visible:false,
                    data:[],//单证专员下拉数据集
                    splitOrderIds:[],//选择的小单id集合
                    userId:''//选择的单证专员
                }
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new SplitOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    belongMaxId:dataObj.otherParams.belongMaxId,
                    fileSize:80,
                    accept:'.xlsx,.xls',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //文件上传之后
        const afterResult=(res:any)=>{
            proxy.pageListRef.queryHandler();
        }
        //文件删除之后
        const afterRemove=(file:any,fileList:any)=>{
            proxy.pageListRef.queryHandler();
        }
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'psbFlag'==params.comboId){
                    return [
                            {value:0,label:'未上传'},{value:1,label:'待提交'},
                            {value:2,label:'大区经理驳回'},{value:3,label:'待大区经理审核'},
                            {value:4,label:'技术驳回'},{value:5,label:'待技术审核'},
                            {value:6,label:'审核通过'}
                        ];
                }
            }
        })
        //维护订单评审表按钮事件
        const ddpsbHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(selectRows.length>1){
                    proxy.$message('只能对单个小单进行订单评审表维护');
                    return ;
                }
                dataObj.otherParams.visible=true;
                //只是这样修改了，在upload组件中不会改变belongMaxId的值，必须在nextTick里面显示的改变upload中的belongMaxId，才会发起加载附件的请求
                //否则每行记录打开的订单评审表都一样
                // dataObj.otherParams.belongMaxId=selectRows[0].F_ID;
                nextTick(()=>{
                    dataObj.refMap.get('ddpsb').uploadParams.belongMaxId=selectRows[0].F_ID;
                    let canEditArr=['未上传','待提交','大区经理驳回','技术驳回'];//只有这几种状态才能修改附件
                    dataObj.refMap.get('ddpsb').uploadParams.disabled=!canEditArr.includes(selectRows[0].F_PSB_FLAG);
                })
            }
        }
        //提交
        const submitHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(selectRows.length>1){
                    proxy.$message('只能单个提交');
                    return ;
                }
                await dataObj.utilInst.submit(selectRows[0]);
            }
        }
        //查看审批日志
        const approveLogHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows)await dataObj.utilInst.approveLog(selectRows[0]);
        }
        //分配单证专员
        const fpdzzyHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                if(dataObj.otherParams.fpdzzy.data.length==0){//节约一点，当第一次点击分配订单专员的时候，去后台加载所有的订单专员
                    let res = await utils.OrderProviderApi.getAllDzzy({});
                    if(res.result)dataObj.otherParams.fpdzzy.data=res.data;
                }
                dataObj.otherParams.fpdzzy.splitOrderIds=[];//先清空
                selectRows.forEach((item:any)=>dataObj.otherParams.fpdzzy.splitOrderIds.push(item['F_ID']));
                dataObj.otherParams.fpdzzy.visible=true;
            }
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_SPLIT_ORDER_CODE,
                cardFrom:'SplitOrderList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),buildUploadInitParams,handlePreview,afterResult,afterRemove,comboSelect,ddpsbHandler,
            submitHandler,approveLogHandler,fpdzzyHandler,orderDetail
        }
    }
});