import BaseBean from "@/utils/BaseBean";

export interface ISplitOrderListDataObj {
    utilInst:SplitOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class SplitOrderListUtil extends BaseBean{
    public dataObj:ISplitOrderListDataObj

    constructor(proxy:any,dataObj:ISplitOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //得到的选择行
    public getSelectedRow():any{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.proxy.$message('请选择要操作的行');
            return;
        }
        return selectRows;
    }
    //提交
    public async submit(row:any):Promise<any>{
        if(row['F_PSB_FLAG']=='未上传'){
            this.proxy.$message({type:'warning',message:'还未上传订单评审表'});
            return;
        }
        let canSubmitArr=['待提交','大区经理驳回','技术驳回'];//只有这几种状态才能提交小单的评审表去大区经理审核
        if(!canSubmitArr.includes(row['F_PSB_FLAG'])){
            this.proxy.$message({type:'warning',message:'当前状态不允许提交订单评审表'});
            return ;
        }
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                let res = await this.utils.OrderProviderApi.processPiDdpsb({ params:{id:row['F_ID'], type:'SplitOrderList'}});
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.pageListRef.queryHandler(true);
                }
            }
        });
    }
    //查看小单的订单评审表日志
    public async approveLog(row:any):Promise<any>{
        if(row['F_PSB_FLAG']=='未上传'){
            this.proxy.$message('请先上传订单评审表');
            return;
        }
        let res = await this.utils.OrderProviderApi.approveLog({ params:{id:row.F_ID}});
        if(res.result){
             this.dataObj.otherParams.approveLog.visible=true;
             this.dataObj.otherParams.approveLog.data=res.list;
        }
    }
    //分配单证专员，请求后台
    public async doFpdzzy(row:any):Promise<any>{
        if(!this.dataObj.otherParams.fpdzzy.userId){
            this.proxy.$message({type:'error',message:'请选择订单专员'});
            return ;
        }
        let params:any={ids:this.dataObj.otherParams.fpdzzy.splitOrderIds,userId:this.dataObj.otherParams.fpdzzy.userId};
        let res = await this.utils.OrderProviderApi.fpddzy({params:params});
        if(res.result){
            this.dataObj.otherParams.fpdzzy.visible=false;
            this.utils.Tools.success();
            this.proxy.pageListRef.queryHandler(true);
        }
    }
}